export enum KBItemTypeEnum {
    Tree = 0,
    Article = 1,
    Category = 2,
}

export enum KBItemStatusEnum {
    Published = 1,
    Unpublished = 0,
}

export enum KBFavoriteArticleTypeEnum {
    Popular = 'popular',
    Custom = 'custom',
}
